@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;600&display=swap");
@font-face {
  font-family: "Helvetica";
  src: url(./fonts/Helvetica-Bold.ttf) format("truetype");
  src: url(./fonts/Helvetica.ttf) format("truetype");
}
.qna, .section, .hero_section {
  margin: 2rem 0;
  padding: 0 1rem;
}

.bold {
  font-weight: 700;
}

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.row {
  flex-direction: row;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-family: "Unbounded", cursive;
  color: #261447;
  text-align: center;
}

.left-align {
  padding: 1em;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  background-color: #f9f8f8;
  padding: 0.8em;
  max-width: 15 0px;
  margin: 0 auto;
}
nav img {
  width: 8rem;
}
nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 0.8em;
  font-family: "Unbounded", cursive;
}
nav ul li {
  list-style: none;
}

footer {
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 3px 0px #ff3864 inset;
  padding: 1em 0;
  padding: 1em;
  background-color: #f9f8f8;
}
footer .main-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}
footer .branding {
  padding: 1em 0;
}
footer .heading {
  font-family: "Unbounded", cursive;
  max-width: 20ch;
  color: #ff3864;
  font-size: 1.4rem;
}
footer .copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  font-size: 0.8em;
}
footer .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}
footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  flex-flow: column wrap;
  padding-bottom: 1em;
}
footer ul li {
  list-style: none;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  gap: 1em;
  background-color: #087ca7;
  border-radius: 10px;
  padding: 2em;
}
.card .text h1 {
  color: #f9f8f8;
  margin-bottom: 1em;
  padding: 0.5em 0;
  text-align: left;
}
.card .text p {
  max-width: 24ch;
  color: #f9f8f8;
  line-height: 106%;
}
.card img {
  width: 40%;
  max-width: 300px;
}

.card:last-of-type {
  margin-bottom: 3em;
}

.card_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  max-width: 1200px;
  margin: 0 auto;
}

.hero_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 2em;
  padding-top: 2em;
  padding-bottom: 2em;
  margin-bottom: 2em;
}
.hero_section img {
  width: 100%;
  max-width: 400px;
}
.hero_section section {
  margin-bottom: 2em;
}
.hero_section .branding {
  color: #261447;
  font-family: "Unbounded", cursive;
  font-weight: 600;
  font-size: 2em;
  line-height: 92.5%;
  margin-bottom: 1.5em;
  max-width: 15ch;
}
.hero_section .subheading {
  color: #087ca7;
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 1rem;
}
.hero_section button {
  padding: 1rem 1.2rem;
  border-radius: 1000px;
  border: none;
  background-color: #ff3864;
  font-family: "Unbounded", cursive;
  font-weight: 400;
  font-size: 1.2em;
  color: #f9f8f8;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 2em;
  font-weight: 700;
  line-height: 106%;
  color: #22181c;
  margin-bottom: 4rem;
}
.section div {
  max-width: 60ch;
  margin: 0 auto;
  padding-bottom: 1em;
}
.section img {
  width: 100%;
  max-width: 400px;
}

.qna h2 {
  font-family: "Unbounded", cursive;
  color: #261447;
  text-align: center;
}
.qna h2 span {
  color: #ff3864;
}
.qna div:first-of-type {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  line-height: 106%;
}
.qna div:first-of-type p {
  margin: 0 auto;
  max-width: 50ch;
}
.qna div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  gap: 2em;
}
.qna div img {
  width: 100%;
  max-width: 200px;
}
.qna div p {
  max-width: 60ch;
}

.payment-btn {
  padding: 0.8rem 1.2rem;
  border-radius: 1000px;
  border: none;
  background-color: #ff3864;
  font-size: 1.2em;
  font-family: "Unbounded", cursive;
  font-weight: 400;
  color: #f9f8f8;
  margin-bottom: 1em;
}

.about {
  padding: 0 1em;
}
.about div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about div img {
  width: 80%;
  max-width: 600px;
}
.about h1 {
  font-family: "Unbounded", cursive;
  color: #261447;
  text-align: center;
  padding: 1em;
}
.about p {
  line-height: 106%;
  color: #22181c;
  margin-bottom: 1em;
}

.plan h1 {
  text-align: start;
}
.plan ul {
  list-style: none;
}
.plan ul li {
  list-style: none;
  padding: 1em;
}

@media (max-width: 820px) {
  .card_container {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1.5em;
  }
  .hero_section {
    min-height: 70vh;
  }
  .qna div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    line-height: 106%;
  }
  .qna div:first-of-type p {
    margin: 0 auto;
    max-width: 50ch;
  }
  .qna div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    padding: 1em;
    gap: 2em;
  }
  .qna div img {
    width: 100%;
    max-width: 200px;
  }
  .qna div p {
    max-width: 60ch;
  }
  footer {
    padding: 1em 0;
    background-color: #f9f8f8;
  }
  footer .main-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    max-width: 100vw;
    margin: 0 auto;
  }
  footer .branding {
    text-align: center;
  }
  footer .branding .heading {
    font-family: "Unbounded", cursive;
    max-width: 20ch;
    color: #ff3864;
    font-size: 1.1rem;
  }
  footer .copyright {
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 0;
  }
  footer .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
  }
  footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3em;
    flex-flow: column wrap;
    padding-bottom: 1em;
  }
  footer ul li {
    list-style: none;
  }
}/*# sourceMappingURL=Style.css.map */